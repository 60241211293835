import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

export interface NotFoundProps {}

const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return 'en'
    }

    const lang = navigator && navigator.language && navigator.language.split('-')[0]
    if (!lang) return 'en'

    switch (lang) {
        default:
            return 'en'
    }
}

const NotFound: React.FC<NotFoundProps> = () => {
    useEffect(() => {
        const urlLang = getRedirectLanguage()

        navigate(`/${urlLang}`, { replace: true })
    }, [])

    return null
}

export default NotFound
